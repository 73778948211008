import React, { useState } from 'react';
import PropTypes from "prop-types"
// import { StaticQuery, graphql } from "gatsby";
import Loader from "./loader";
import Header from "./header"
import Footer from "./footer"
import "../sass/custom.scss"

const LayoutLoader = ({ children, classNames }) => {
  const [isLoading, setIsLoading] = useState(true);
  
  return (
      
         isLoading ? (
                <Loader finishLoading={() => setIsLoading(false)} />
              ) : (

    <div id="page" className={`site ${classNames}`}>
      <a className="skip-link screen-reader-text" href="#content">
        Skip to content
      </a>
      <Header siteTitle={"M2 Blog"} />
      <div id="content" className="site-content">
      <p>loader gif test page</p>
        <section id="primary" className="content-area">
          <main id="main" className="site-main">
            {children}
          </main>
          {/* #main */}
        </section>
        {/* #primary */}
      </div>
      <div
        style={{
          margin: `0 auto`,
          paddingTop: 0,
        }}
      >
        <Footer />
      </div>
    </div>

  )
)
  }

LayoutLoader.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutLoader
